import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
    const [ offCanvasIsOpen, setOffCanvasIsOpen ] = useState(false);
    const { hash } = useLocation();
    const Navigate = useNavigate();

    const handleCloseOffcanvas = () => {
        const closeBtn = document.querySelector("#offcanvasCloseBtn");
        if (closeBtn) {
            closeBtn.click();
        }
    }

    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

    useEffect(() => {
      const offcanvasElement = document.getElementById('offcanvas');
  
      const handleOpenChange = () => {
        if (isOffcanvasOpen) {
          offcanvasElement.classList.add('show');
        } else {
          offcanvasElement.classList.remove('show');
        }
      };
  
      handleOpenChange();
  
      return () => {
        offcanvasElement.classList.remove('show');
      };
    }, [isOffcanvasOpen]);
  
    const toggleOffcanvas = () => {
      setIsOffcanvasOpen(!isOffcanvasOpen);
    };
    
    return (
        <nav className="min-vh-lg-10 py-3 py-lg-0 bg-black position-sticky top-0 z-10 d-flex justify-content-center buttonMenu-zh header-navbar">
            <div className="row d-flex justify-content-center w-100">
                <div className="container d-flex justify-content-between">
                    <div className="col-3 py-lg-4">
                        <h1>
                            <Link to="/" className="logo_link" onClick={() => window.scrollTo(0, 0)}>暮帝納斯</Link>
                        </h1>
                    </div>
                    {/* 桌機 */}
                    <ul className="d-none d-lg-flex col-7">
                        <li className="li-link">
                            <Link to="/#products" className="d-flex justify-content-center align-items-center w-100 h-100">產品介紹</Link>
                        </li>
                        <li className="li-link">
                            <Link to="/#sales" className="d-flex justify-content-center align-items-center w-100 h-100">銷售通路</Link>
                        </li>
                        <li className="li-link">
                            <Link to="/#knowledge" className="d-flex justify-content-center align-items-center w-100 h-100">知識分享</Link>
                        </li>
                        <li className="li-link">
                            <Link to="/#qa" className="d-flex justify-content-center align-items-center w-100 h-100 buttonMenu-en">Q&A</Link>
                        </li>
                        <li className="li-link">
                            <Link to="/#brand-story" className="d-flex justify-content-center align-items-center w-100 h-100">品牌故事</Link>
                        </li>
                        <li className="li-link">
                            <Link to="/#contact" className="d-flex justify-content-center align-items-center w-100 h-100">聯絡我們</Link>
                        </li>
                    </ul>
                    {/* 手機、平板 */}
                    <div className="d-lg-none d-flex align-items-center">
                        {!offCanvasIsOpen && (
                            <button id="offcanvasCloseBtn" className="btn hamburgerMenuBtn d-flex flex-column justify-content-between" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvas" onClick={toggleOffcanvas}>
                                <div className="hamburgerMenuLine"></div>
                                <div className="hamburgerMenuLine"></div>
                                <div className="hamburgerMenuLine"></div>
                            </button>
                        )}
                        {offCanvasIsOpen && (
                            <button id="offcanvasCloseBtn" className="btn hamburgerMenuBtn d-flex flex-column justify-content-between" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvas" onClick={toggleOffcanvas}>
                                <div className="hamburgerMenuLine" style={{transform: "rotate(45deg)"}}></div>
                                <div className="hamburgerMenuLine" style={{transform: "rotate(-45deg)"}}></div>
                            </button>                        
                        )}

                        <div className="offcanvas offcanvas-end d-lg-none border-0 w-100 header-offcanvas" tabindex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
                            <div className="offcanvas-body bg-third text-primary py-15">
                                <ul className="w-100 h-100 px-2 d-flex flex-column align-items-center buttonMenu-zh fw-normal">
                                    <li className="li-link white-space-nowrap w-fit-content border-0">
                                        <Link to="/#products" onClick={handleCloseOffcanvas}>產品介紹</Link>
                                    </li>
                                    <li className="li-link white-space-nowrap w-fit-content mt-30px border-0">
                                        <Link to="/#sales" onClick={handleCloseOffcanvas}>銷售通路</Link>
                                    </li>
                                    <li className="li-link white-space-nowrap w-fit-content mt-30px border-0">
                                        <Link to="/#knowledge" onClick={handleCloseOffcanvas}>知識分享</Link>
                                    </li>
                                    <li className="li-link white-space-nowrap w-fit-content mt-30px buttonMenu-en border-0">
                                        <Link to="/#qa" onClick={handleCloseOffcanvas}>Q&A</Link>
                                    </li>
                                    <li className="li-link white-space-nowrap w-fit-content mt-30px border-0">
                                        <Link to="/#brand-story" onClick={handleCloseOffcanvas}>品牌故事</Link>
                                    </li>
                                    <li className="li-link white-space-nowrap w-fit-content mt-30px border-0">
                                        <Link to="/#contact" onClick={handleCloseOffcanvas}>聯絡我們</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}