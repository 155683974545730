export default function Footer() {
    return (
        <div className="bg-black text-white py-5 py-md-8 py-lg-10 footer text-center position-relative z-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="d-flex flex-column flex-xl-row">
                                <p className="order-1 order-xl-2 md-text-start">高雄市前金區五福三路54號6F-2</p>
                                <p className="mt-3 mt-xl-0 footer-copyright order-2 order-xl-1">COPYRIGHT © 暮帝納斯  All right reserved. </p>
                            </div>
                            <p className="mt-3 mt-md-0 xl-align-self-end d-md-flex align-items-md-end">POWER BY <a className="text-white ms-md-1" href="https://effectstudio.com.tw/zh" target="_blank" noopener noreferrer>影響視覺科技</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}