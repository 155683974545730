import line from "../assets/images/cta/line.png";
import fb from "../assets/images/cta/fb.png";
import top from "../assets/images/cta/top.png";

export default function CTA() {
    const lineID = "@tst_boterasu";
    const lineURL = "https://line.me/ti/p/" + lineID;

    return (
        <div className="d-flex flex-column w-fit-content position-fixed cta-container">
            <div>
                <img src={line} alt="our line account" className="cursor-pointer" onClick={() => window.open(lineURL, "_blank")}></img>
            </div>
            <div>
                <img src={fb} alt="our fb account" className="cursor-pointer" onClick={() => window.open("https://www.facebook.com/BOTERASU1913?mibextid=ZbWKwL", "_blank")}></img>
            </div>
            <div>
                <img src={top} alt="scroll to top" className="cursor-pointer" onClick={() => window.scrollTo(0, 0)}></img>
            </div>
        </div>
    )
}