import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import "bootstrap/dist/js/bootstrap";

import Header from "./components/Header";
import Footer from "./components/Footer";
import CTA from "./components/CTA";
import Homepage from "./pages/Homepage";

const KnowledgeList = lazy(() => import("./pages/KnowledgeList"));
const KnowledgeDetail = lazy(() => import("./pages/KnowledgeDetail"));

function App() {
  return (
    <div className="position-relative" style={{ minHeight: "100vh" }}>
        <Header />
        <Routes>
          <Route exact path="/" element={
            <React.Suspense fallback={<div></div>}>
              <Homepage />
            </React.Suspense>}>
          </Route>
          <Route exact path="/knowledge" element={
            <React.Suspense fallback={<div></div>}>
              <KnowledgeList />
            </React.Suspense>}>
          </Route>
          <Route exact path="/knowledge/:id" element={
            <React.Suspense fallback={<div></div>}>
              <KnowledgeDetail />
            </React.Suspense>}>
          </Route>
        </Routes>
        <CTA />
        <Footer />
    </div>
  );
}

export default App;