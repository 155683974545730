import React, { lazy } from 'react';

const Slider = lazy(() => import("../page-sections/Slider"));
const Product = lazy(() => import("../page-sections/Product"));
const Sales = lazy(() => import("../page-sections/Sales"));
const Knowledge = lazy(() => import("../page-sections/Knowledge"));
const QA = lazy(() => import("../page-sections/QA"));
const BrandStories = lazy(() => import("../page-sections/BrandStories"));
const Contact = lazy(() => import("../page-sections/Contact"));

export default function Homepage() {
    return (
        <>
            <section>
                <Slider />
                <Product />
                <Sales />
                <Knowledge />
                <QA />
                <BrandStories />
                <Contact />
            </section>
        </>
    )
}